<template>
  <!-- 新闻详情 -->
  <div class="news-detail">
    <div class="container-w1200 detail-box">
      <!-- 面包屑 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="hand" @click.native="gohome"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ name: 'News' }">新闻资讯</el-breadcrumb-item>
        <el-breadcrumb-item class="current">
          {{ newInfo.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 新闻内容 -->
      <div class="content">
        <div class="content-head">
          <h4 class="head-title">{{ newInfo.title }}</h4>
          <span class="head-date">{{ newInfo.date }}</span>
        </div>
        <p v-html="newInfo.text"></p>
      </div>

      <div class="up-and-down">
        <div v-if="prev" @click="changNew(0)">上一篇：{{ prevNew.title }}</div>
        <div class="down" v-if="next" @click="changNew(1)">
          下一篇：{{ nextNew.title }}
        </div>
      </div>
    </div>

    <!-- 版权 -->
    <Copyright />
  </div>
</template>

<script>
import dataInfo from '@/utils/newsInfo'
import Copyright from '@/components/Copyright'

export default {
  name: 'NewsDetail',
  components: {
    Copyright
  },

  data() {
    return {
      // 新闻信息
      newInfo: {},
      // 上一篇新闻
      prevNew: {},
      prev: true,
      // 下一篇新闻
      nextNew: {},
      next: true
    }
  },

  mounted() {
    this.getInfo()
  },

  methods: {
    // 获取 新闻详情
    getInfo() {
      const newsIndex = Number(sessionStorage.getItem('newsIndex'))
      console.log(typeof newsIndex)
      this.newInfo = dataInfo[newsIndex]
      this.$nextTick(() => {
        this.scrollTop()
      })

      this.setNews(newsIndex)
    },

    // 设置上一篇和下一篇
    setNews(newsIndex) {
      //  如果 newsIndex !== 0; 那么 才能有上一篇新闻
      if (newsIndex) {
        //
        const prevNewInfo = dataInfo[newsIndex - 1]
        this.prevNew = {
          title: prevNewInfo.title,
          index: newsIndex - 1
        }
        this.prev = true
      } else {
        this.prev = false
      }

      // 如果 newsIndex + 1 < 新闻的长度，那么才能有下一篇新闻
      if (newsIndex + 1 < dataInfo.length) {
        const nextNewInfo = dataInfo[newsIndex + 1]
        this.nextNew = {
          title: nextNewInfo.title,
          index: newsIndex + 1
        }
        this.next = true
      } else {
        this.next = false
      }
    },

    // 切换新闻
    changNew(num) {
      if (num) {
        // 下一篇
        sessionStorage.setItem('newsIndex', this.nextNew.index)
      } else {
        // 上一篇
        sessionStorage.setItem('newsIndex', this.prevNew.index)
      }
      this.getInfo()
    },

    scrollTop() {
      let top = document.body.scrollTop || document.documentElement.scrollTop
      // console.log('top', top)

      let scrollToptimer = setInterval(function() {
        top = top - 40
        if (top > 0) {
          window.scrollTo(0, top)
        } else {
          clearInterval(scrollToptimer)
        }
      }, 20)
    },

    // 跳转到 首页 设置 active
    gohome() {
      // this.$EventBus.$emit('setActive', 'Home')
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-detail {
  background-color: #fcfcfc;

  .detail-box {
    margin: 0 auto;
    padding-top: 30px;
  }
}

.content {
  width: 100%;
  padding: 34px 20px 40px;
  background-color: #fff;

  .content-head {
    text-align: center;

    .head-title {
      font-size: 24px;
      color: #333;
    }

    .head-date {
      display: inline-block;
      font-size: 14px;
      color: #333;
      margin: 17px 0 20px;
    }
  }
}

.up-and-down {
  // display: flex;
  // flex-direction: column;
  margin: 24px 0;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  // display: inline-block;

  .down {
    color: #08467c;
    border-bottom: 1px solid #08467c;
    margin-top: 15px;
    display: inline-block;
  }
}

.hand {
  cursor: pointer;
}

.current ::v-deep .el-breadcrumb__inner {
  color: #3979ff !important;
}

.el-breadcrumb {
  font-size: 16px;
  margin-bottom: 45px;
}

.el-breadcrumb ::v-deep .is-link {
  color: #666;
  font-weight: 400;
}
</style>
